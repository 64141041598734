:root {
  --primary: #f9e04f;
  --secondary: #005d67;
  --tertiary: #003b4d;
  --quaternary: #39beae;
  --penitentiary: #f5821f;
  --gold: #ffde54;
  
  --primary-50: rgba(249, 223, 79, 0.5);
  --black-80: rgba(0, 0, 0, 0.8);

  --primary-text: #f4d854;
  --secondary-text: #005d67;
  --tertiary-text: #003b4d;
  --quaternary-text: #39beae;

  --text-58595b: #58595b;
  --text-6d6e71: #6d6e71;
  --text-a9aaad: #a9aaad;
  --text-white: #ffffff;
  --text-808285: #808285;
  --text-939598: #939598;
  --text-a3a3a3: #a3a3a3;
  --text-f8f5f4: #f8f5f4;
  --bg-ededee: #ededee;
  --bg-f0f1f1: #f0f1f1;
  --bg-ebebec: #ebebec;
  --bg-ffffff: #ffffff;
  --bg-acacac: #acacac;
  --bg-6d6e71: #6d6e71;
  --bg-d1d3d4: #d1d3d4;
  --bg-939598: #939598;
  --bg-efeff0: #efeff0;
  /* --heading-font: 'DejaVu Sans';
    --body-font: 'DejaVu Sans ExtraLight'; */
  /*--heading-font: 'Dongle', sans-serif; */
  --heading-font: 'Barlow', sans-serif;
  --body-font: 'Barlow', sans-serif;
  
  --box-padding: 30px;
}


.stat-box{display: flex;align-items: center;gap: 10px;background-color: var(--text-white);border-radius: 20px;border: 2px solid var(--bg-d1d3d4);padding: 15px;}
.stat-box img {width: 35px;}
.stat-box h5{font-size: 18px; font-weight: 400;}

.product-suggestion-section{}
.product-suggestion-section h2{font-weight: 600;}
.personalized-formula{padding: 30px;border-radius: 20px;background-color: #fff;border: 2px solid #d1d3d4;}
.personalized-formula{}
.personalized-formula h5{color: #939598;}
.personalized-formula table th,
.personalized-formula table td{font-size: 18px;}
.personalized-formula table th{font-size: 20px;color: #939598;}
.personalized-formula table td a{color: #939598;font-size: 20px;font-weight: 500;}
.personalized-formula a.text-muted img{transform: rotate(180deg);}
.personalized-formula a.text-muted.collapsed img{transform: rotate(0deg);}
.personalized-formula a{}
.personalized-formula table td {border: 0; padding: 0.3rem 0;}
.personalized-formula table td a i {font-size: 16px; margin-left: 7px;}
.personalized-formula table td p{font-size: 14px;color: #939598;font-weight: 500;margin-top: 10px;}
.personalized-formula table td:nth-child(2),
.personalized-formula table td:nth-child(3){
  text-align: right;
  text-wrap: nowrap;
}
/*.personalized-formula div.table-responsive {height: 500px !important;}*/
.personalized-formula table thead tr th { background-color: #fff; }
.personalized-formula table tr th { border-bottom: 2px solid rgb(33, 37, 41); }
.personalized-formula table th { position: sticky; top: 0; }

.personalized-formula div.table-responsive::-webkit-scrollbar {
  width: 5px;
}
.personalized-formula div.table-responsive::-webkit-scrollbar-track {
  background: transparent;
}
.personalized-formula div.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.pricing-box{padding: 40px;background-color: #fff;border: 2px solid #d1d3d4;border-radius: 15px;text-align: center; cursor: pointer;}
.pricing-box img{}
.pricing-box h4{margin: 20px 0;font-weight: 600;}
.pricing-box p{margin-bottom: 10px;}
.pricing-box p:last-child{margin-bottom: 0;}
.pricing-box.pricing-active{color: var(--text-white);background-color: var(--secondary);}
.pricing-box .pricing-active {}


.sticky-top-products {
  position: sticky;
  top: 100px;
  /* background-color: #003b4d; */
  height: fit-content;
  /* z-index: 10; */
}


@media(max-width:1200px){}
@media(max-width:576px){
  .personalized-formula{padding: 15px;}
  .personalized-formula table td a,
  .personalized-formula table td,

  .personalized-formula table th{font-size: 13px;}
  .personalized-formula table td a i {font-size: 14px; margin-left: 7px;}
}

