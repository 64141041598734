.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon,
.flag-icon-background {
  
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
  &.flag-icon-squared {
    width: 1em;
  }
}


  .flag-icon-us {
    background-image: url(../fonts/flag-icon/us.svg);
    &.flag-icon-squared {
      background-image: url(../fonts/flag-icon/us.svg);
    }
  }

  .flag-icon-de {
    background-image: url(../fonts/flag-icon/de.svg);
    &.flag-icon-squared {
      background-image: url(../fonts/flag-icon/de.svg);
    }
  }
