@media(min-width:1200px) {

  .mobile-overlay,
  .mobile-sidebar {
    display: none;
  }

  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px;
  }
}

@media(max-width:1200px) {

  /* .home-banner .home-text h1 {
    line-height: 0.7;
  } */

  ul.navbar-nav {
    background-color: #ffffff;
    margin: 10px 0 0 0 !important;
    padding: 20px 20px;
    border-radius: 10px;
  }

  .owl-experts .owl-nav {}

  .owl-experts .owl-nav button {
    margin-top: -26px;
    position: absolute;
    top: 30%;
    color: #cdcbcd;
  }

  .owl-experts .owl-nav i {}

  .owl-experts .owl-nav .owl-prev {
    left: 0px;
  }

  .owl-experts .owl-nav .owl-next {
    right: 0px;
  }

  .owl-experts .owl-nav .owl-prev img,
  .owl-experts .owl-nav .owl-next img {
    width: 30px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .home-banner .owl-carousel .owl-dots {
    left: unset;
    right: 0;
    bottom: 40px;
  }

  .title3,
  .title2,
  footer .footer-item .title2 {
    font-size: 28px;
  }

  .title3 br {
    display: none;
  }

  .blog-detail-inner {
    font-size: 16px;
  }

  .blog-detail-inner .fs-4 {
    font-size: 20px !important;
  }

  .title2 {
    line-height: 1;
    margin-bottom: 15px;
  }

  .testimonials .testimonials-body h3 {
    font-size: 30px;
  }


  .discover .title2 {}




  .mobile-overlay {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1049;
    opacity: 0;
    visibility: hidden;
    transition: .7s;
  }

  .mobile-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 1050;
    /* padding: 10px; */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mobile-sidebar img {
    margin-bottom: 40px;
  }

  .mobile-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-sidebar li {
    padding: 0 0px;
    margin-bottom: 15px;
  }

  .mobile-sidebar li a.nav-link {
    color: var(--secondary-text);
    font-weight: 600;
  }

  .navbar-toggler {}

  .sidebar-active .mobile-overlay,
  .sidebar-active .mobile-sidebar {
    opacity: 1;
    visibility: visible;
  }

  a.sidebar-close {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: var(--secondary-text);
    color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  a.sidebar-close:hover {
    opacity: 0.9;
  }



}

@media(max-width:1200px) {
  .home-banner {
    background-size: cover;
  }

  .home-banner .home-text-wrapper {
    padding: 180px 0 170px 0;
    position: relative;
    z-index: 1;
  }

  .faq .row>div:first-child {
    padding: 80px 40px;
  }
}

@media(max-width:992px) {

  .faq .row>div:last-child,
  .faq .row>div:first-child {
    padding: 20px 20px;
  }

  .faq .accordion .accordion-item .accordion-header button {
    padding: 10px 0;
  }


}

@media(max-width:768px) {
  .sub-title-uppercase {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }

  .services .services-item h4 {
    font-size: 18px;
  }

  .advantages .advantages-box ul li {
    font-size: 14px;
  }

  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .advantages {
    padding-top: 130px;
  }

  .home_shape1 {
    left: -180px;
    top: 7%;
  }

  .home_shape2 {
    left: 200px;
    top: -40px;
  }

  .home_shape3 {
    right: -90px;
    top: 100px;
    background-color: var(--tertiary);
  }

  .home_shape4 {
    right: 0;
    left: 0;
    margin: auto;
    bottom: -200px;
    width: 400px;
    height: 400px;
  }

  .experts {
    padding-bottom: 250px;
  }

  .experts-wrapper {
    bottom: -370px;
  }

  .experts {
    padding-bottom: 200px;
  }
}

@media(max-width:576px) {
  .faq .accordion .accordion-item .accordion-header button img:nth-of-type(1) {
    display: none;
  }

  .home-banner .home-text h1 {
    font-size: 20px;
  }

  .home-banner .home-text h2 {
    font-size: 18px;
  }

  .home-banner .home-text h5 {
    font-size: 16px;
  }

  .home-banner .home-text ul {
    font-size: 16px;
  }

  .home-banner .home-text-wrapper {
    padding: 60px 0 60px 0;
    position: relative;
    z-index: 1;
    text-shadow: 1px 1px 0px var(--secondary);
  }

  .title2 {
    font-size: 28px;
  }

  footer .footer-item .title2 {
    font-size: 24px;
  }

  .faq .accordion .accordion-item .accordion-header button {
    font-size: 14px;
  }

  .faq .accordion .accordion-item .accordion-header button img:nth-of-type(2) {
    width: 20px;
  }

  .sub-title2 {
    font-size: 18px;
  }

  .header-bottom-bar h5 {
    font-size: 18px;
    line-height: 1;
  }

  /* .btn-custom-tertiary {
  font-size: 26px;
} */
  .experts .img-style img {
    width: 280px;
    height: 280px;
    padding: 30px;
  }

  .experts-wrapper {
    bottom: -310px;
  }

  .experts {
    padding-bottom: 150px;
  }

  .profile-reviews-wrapper {
    align-items: unset;
    flex-direction: column;
    gap: 20px;
  }
  
  body{--box-padding: 15px;}
}
