.checkout-page{}
.checkout-page {}

.checkout-item .card-header{ background-color: var(--secondary);color: var(--text-white);}
.checkout-item h1{font-family: var(--heading-font);font-weight: 500;margin: 0;margin-bottom: -5px;}
.checkout-item img{max-width: 180px;object-fit: contain;}
.checkout-item .card-body{padding: 35px;position: relative;}
.checkout-item p{color: #a9aaad;font-weight: 600;font-size: 18px;}
.checkout-item ul{display: flex;align-items: center;gap: 10px;margin: 0;padding: 0;list-style: none;flex-wrap: wrap;}
.checkout-item ul li{position: relative;padding-left: 10px;border: 1px solid #a3a3a3;color: #a3a3a3;padding: 3px 15px 3px 20px;border-radius: 50px;}
.checkout-item ul li span { width: 6px; height: 6px; background-color: var(--secondary); content: ''; position: absolute; top: 0; bottom: 0; left: 8px; margin: auto; border-radius: 50%;}

.checkout-item .price{text-align: right;}
.close-btn{position: absolute;right: 30px;top: 30px;}


.box-style-2{ padding: 40px; background-color: var(--bg-efeff0); border-radius: 15px;}
.form-control:focus{box-shadow: none;}
.box-style-2 .title{font-weight: 600;}
.box-style-2 .form-control{padding: 12px 20px;font-size: 18px;text-transform: uppercase;font-weight: 500;border-color: #acacac;border-radius: 7px;}

.checkout-box{}
.checkout-box .checkout-header {display: flex;justify-content: space-between;align-items: center;}
.checkout-box .checkout-header h1{font-family: var(--heading-font);font-weight: 500;}
.checkout-box .checkout-header h5{color: #808285;text-transform: uppercase;}
.checkout-box .checkout-body {padding: 20px 0;}
.checkout-box .checkout-body h4{text-transform: uppercase;font-weight: 600;color: #58595b;margin-bottom: 30px;}
.checkout-box .checkout-body h5{text-transform: uppercase;color: #808285;}

.checkout-body table {color: #808285;text-transform: uppercase;}

.coupan-box{position: relative;}
.coupan-box .form-control{border-radius: 50px;padding: 10px 120px 10px 20px;}
.coupan-box .btn{position: absolute;right: 0;top: 0;bottom: 0;height: 100%;background-color: var(--primary);font-size: 18px;display: flex;align-items: center;justify-content: center;text-transform: uppercase;border-radius: 50px;}



.btn-custom-1 { padding: 10px 30px; font-weight: normal; letter-spacing: 1px; background-color: var(--quaternary); color: var(--text-white); font-size: 22px; text-transform: uppercase; border-radius: 30px;}
.box-style-2.checkout-box.sticky-top { top: 95px; z-index: 10;}





@media(max-width:1200px){}
@media(max-width:576px){
  .box-style-2 ,
  .checkout-item .card-body {
    padding: 15px;
  }
  .checkout-box .checkout-header h5{font-size: 16px;}
  .checkout-box .checkout-body h4{font-size: 18px;}
  .checkout-box .checkout-body h5{font-size: 16px;}
}