:root {
  --primary: #f9e04f;
  --secondary: #005d67;
  --tertiary: #003b4d;
  --quaternary: #39beae;
  --penitentiary: #f5821f;
  --gold: #ffde54;
  --yellow: #fbf790;
  --primary-50: rgba(249, 223, 79, 0.5);
  --black-80: rgba(0, 0, 0, 0.8);
  --primary-text: #f4d854;
  --secondary-text: #005d67;
  --tertiary-text: #003b4d;
  --quaternary-text: #39beae;

  --secondary-80: rgba(0, 92, 103, 0.8);

  --text-58595b: #58595b;
  --text-6d6e71: #6d6e71;
  --text-a9aaad: #a9aaad;
  --text-white: #ffffff;
  --text-808285: #808285;
  --text-939598: #939598;
  --text-a3a3a3: #a3a3a3;
  --text-f8f5f4: #f8f5f4;
  --bg-ededee: #ededee;
  --bg-f0f1f1: #f0f1f1;
  --bg-ebebec: #ebebec;
  --bg-ffffff: #ffffff;
  --bg-acacac: #acacac;
  --bg-6d6e71: #6d6e71;
  --bg-d1d3d4: #d1d3d4;
  --bg-939598: #939598;
  --bg-efeff0: #efeff0;
  /* --heading-font: 'DejaVu Sans';
  --body-font: 'DejaVu Sans ExtraLight'; */
  /*--heading-font: 'Dongle', sans-serif; */
  --heading-font: "Barlow", sans-serif;
  --body-font: "Barlow", sans-serif;
  --box-padding: 30px;
}

.barlow {
  font-family: var(--primary-font);
}

.barlow {
  font-family: var(--secondary-font);
}

/*.dongle {
  font-family: var(--secondary-font);
} */

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../images/home/Ellipsis-2.3s-200px.gif) center no-repeat #fff;
  background-size: 200px;
}

.opacity-hide {
  opacity: 0;
  visibility: hidden;
  transition: 1s;
}
.fw-600 {
  font-weight: 600;
}

.btn,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: var(--heading-font); */
}

body {
  font-family: var(--body-font);
  font-size: 15px;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.title-wrapper {
  color: var(--tertiary-text);
  margin-bottom: 60px;
}

.title {
  font-weight: 800;
  color: var(--tertiary-text);
}

.sub-title-uppercase {
  letter-spacing: 1px;
  font-size: 15px;
}

.sub-title {
}

.title2 {
  font-family: var(--secondary-font);
  font-size: 40px;
  color: var(--tertiary-text);
  font-weight: 600;
}
.title3 {
  font-family: var(--heading-font);
  font-size: 40px;
  color: var(--tertiary-text);
  font-weight: 600;
  line-height: 1;
}

.sub-title2 {
  color: var(--secondary);
  font-size: 20px;
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.btn {
  transition: 0.5s;
  padding: 7px 30px;
  font-weight: bold;
}

.btn-custom {
  background-color: var(--primary);
  color: var(--secondary);
  border-radius: 30px;
  font-weight: bold;
  /* font-size: 18px; */
}

.btn-custom:hover {
  opacity: 0.9;
  color: var(--text-white);
  background-color: var(--primary);
}

.btn-custom-outline {
  background-color: transparent;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  text-transform: uppercase;
  border-radius: 30px;
}

.btn-custom-outline:hover {
  background-color: var(--secondary);
  color: var(--text-white);
}

.btn-custom-tertiary {
  background-color: var(--tertiary);
  color: var(--text-white);
  border-radius: 30px;
  font-weight: bold;
  /* font-size: 18px; */
}

.btn-custom-tertiary:hover {
  opacity: 0.9;
  color: var(--text-white);
  background-color: var(--tertiary);
}

.btn-custom-secondary {
  background-color: var(--secondary);
  color: var(--text-white);
  border-radius: 30px;
  font-weight: bold;
  /* font-size: 18px; */
}

.btn-custom-secondary-cancel {
  background-color: #ef7579;
  color: var(--text-white);
  text-transform: uppercase;
  border-radius: 30px;
  font-weight: bold;
  /* font-size: 18px; */
}

.btn-custom-secondary-pause {
  background-color: #e9de51;
  color: var(--text-white);
  text-transform: uppercase;
  border-radius: 30px;
  font-weight: bold;
  /* font-size: 18px; */
}

.btn-custom-secondary:hover {
  /*opacity: 0.9;*/
  background-color: var(--text-white);
  color: var(--secondary);
  border: 1px var(--secondary) solid;
}

.btn-custom-secondary-pause:hover {
  /*opacity: 0.9;*/
  background-color: var(--text-white);
  color: #e9de51;
  border: 1px #e9de51 solid;
}

.btn-custom-secondary-cancel:hover {
  /*opacity: 0.9;*/
  background-color: var(--text-white);
  color: #ef7579;
  border: 1px #ef7579 solid;
}

.btn-custom-quaternary {
  background-color: var(--quaternary);
  color: var(--text-white);
  text-transform: uppercase;
  border-radius: 30px;
  font-weight: bold;
  /* font-size: 18px; */
}

.btn-custom-quaternary:hover {
  /*opacity: 0.9;*/
  background-color: var(--text-white);
  color: var(--quaternary);
  border: 1px var(--quaternary) solid;
}

.btn.me-xl-3.mb-3.mb-xl-0.text-white:hover {
  color: var(--tertiary); /* Adjust the color as needed */
  opacity: 0.9; /* Optional: if you want to slightly reduce the opacity on hover */
}

.navbar {
  padding: 10px 0;
  z-index: 1050;
  width: 100%;
  transition: 0.5s;
  background-color: var(--secondary);
}

.navbar .cart {
  position: relative;
}

.navbar .cart .cart-quantity {
  position: absolute;
  right: -10px;
  top: -10px;
  color: var(--text-white);
  background-color: var(--secondary);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 50%;
}

.navbar .login {
  text-decoration: none;
  color: var(--primary-text);
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid var(--primary);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.navbar .login span {
  margin-left: 5px;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.nav-fixed {
}

.nav-fixed .navbar {
  padding: 10px 0;
}

.navbar-nav .dropdown-menu {
  margin-top: 40px;
}

.navbar-nav .dropdown-menu a {
  color: var(--secondary-text);
  font-weight: bold;
}

.header-bottom-bar {
  text-align: center;
  background-color: var(--tertiary);
  color: var(--quaternary);
  margin: 0;
  padding: 5px 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header-bottom-bar h5 {
  margin: 0;
  font-size: 20px;
}

.home-banner {
  position: relative;
  overflow: hidden;
  background: url(https://riise-media.s3.amazonaws.com/resources/images/homepage-banner-web.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-banner .container {
}

.home-banner .home-text-wrapper {
  padding: 148px 0 140px 0;
  position: relative;
  z-index: 1;
}

.home-banner .home-text {
  color: var(--secondary-text);
}

.home-banner .home-text h2 {
  text-transform: uppercase;
  margin-bottom: 0;
  /* line-height: 1; */
}

.home-banner .home-text h1 {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}

.home-banner .home-text h5 {
  line-height: 1;
}

.home-banner .home-text ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
}

.home-banner .home-text ul li {
  position: relative;
  display: flex;
}

.home-banner .home-text ul li i {
  margin-right: 10px;
  position: absolute;
  left: 0;
}

@media (min-width: 1200px) {
  /* .home-banner .home-text h1{font-size: 43px;}   */
}

.home-banner .owl-carousel .owl-dots {
  position: absolute;
  left: calc(100% - 270px);
  bottom: 0;
}

.services {
}

.services .services-item {
  position: relative;
}

.services .services-item span.number {
  position: absolute;
  left: 0;
  top: -18px;
  font-size: 30px;
  background-color: var(--secondary);
  color: #fff;
  padding: 5px 15px;
}

.services .services-item span.number:after {
  content: "";
  position: absolute;
  right: -17px;
  top: -18px;
  border: 18px solid transparent;
  border-bottom-color: var(--secondary);
  z-index: -1;
}

.services .services-item {
}

.services .services-item img {
  width: 100%;
  border-radius: 0;
}

.services .services-item .services-meta {
  padding: 20px 0 40px 0;
}

.services .services-item h4 {
  color: var(--tertiary-text);
  font-size: 20px;
  font-weight: 600;
}

.services .services-item p {
  color: var(--tertiary);
  margin-bottom: 0;
}

.services .services-item .full-paragraph-link {
  color: inherit;
  text-decoration: none;
  display: block;
}

.services.blogs .services-meta .read-more-link {
  font-weight: bold;
}

.testimonials {
}

.testimonials .testimonials-item {
  padding: 30px;
  background-color: var(--bg-ffffff);
  border-radius: 10px;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .testimonials .testimonials-item {
    padding: 10px; /* Reduced padding for mobile devices */
  }
}

.testimonials .testimonials-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.testimonials .testimonials-header img {
  margin-right: 20px;
  height: 112px;
  width: 112px;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonials .testimonials-header h5 {
  color: var(--tertiary-text);
  font-weight: bold;
}

.testimonials .testimonials-header p {
  color: var(--text-808285);
  margin-bottom: 0;
}

.testimonials .testimonials-body h3 {
  font-family: var(--secondary);
  margin-bottom: 0px;
  color: var(--secondary);
  font-size: 30px;
  font-weight: bold;
}

.testimonials .testimonials-body h6 {
  margin: 10px 0;
  color: var(--secondary);
}

.testimonials .testimonials-body p {
  color: var(--secondary);
  font-weight: 500;
  text-align: justify;
}

.testimonials .testimonials-body .review {
  list-style: none;
  padding: 0;
  margin: 0;
  margin: 10px 0;
}

.testimonials .testimonials-body .review li {
  display: inline-block;
  margin-right: 10px;
}

.testimonials .testimonials-body .review li a {
}

.testimonials .testimonials-body .review li a i {
  font-size: 20px;
  color: var(--bg-acacac);
}

.testimonials .testimonials-body .review li a i.checked-star {
  color: var(--gold);
}

.testimonials .owl-dots {
  text-align: center;
  margin-top: 80px;
}

.steps {
  background-color: var(--bg-f0f1f1);
}

.steps .title {
  font-weight: bold;
}

.steps h6 {
  color: var(--tertiary-text);
  font-weight: normal;
  line-height: 1.6;
}

.steps img {
  max-height: 362px;
}

.steps .title2.title2-sm {
  font-size: 40px;
  font-weight: bold;
}

/* .steps .btn{font-size: 24px;font-weight: bold;padding: 12px 20px;} */

.experts {
  background-color: var(--yellow);
  position: relative;
  padding-bottom: 100px;
}

.experts .title2 {
  margin-bottom: 15px;
}

.experts p {
  color: var(--tertiary-text);
  font-weight: normal;
  /* font-size: 20px; */
}

.experts-wrapper {
  margin-top: 10px;
  text-align: center;
}

.experts .img-style {
}

.experts .img-style img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  padding: 60px;
  margin-bottom: 20px;
}

.img-style h3:nth-of-type(1) {
  color: var(--tertiary-text);
  font-weight: bold;
}

.img-style h3:nth-of-type(2) {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: var(--tertiary-text);
}

.experts h6 {
}

.experts-item {
  text-align: center;
}

.experts .experts-item img {
  height: 203px;
  width: 203px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--bg-acacac);
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.experts-item h2 {
  font-size: 35px;
  font-family: var(--secondary-font);
  color: var(--tertiary-text);
  margin-bottom: 0;
  line-height: 1;
}

.experts-item p {
  line-height: 1.2;
}

.owl-experts {
  position: relative;
}

.owl-experts .owl-nav {
}

.owl-experts .owl-nav button {
  margin-top: -26px;
  position: absolute;
  top: 30%;
  color: #cdcbcd;
}

.owl-experts .owl-nav i {
  font-size: 52px;
}

.owl-experts .owl-nav .owl-prev {
  left: -110px;
}

.owl-experts .owl-nav .owl-next {
  right: -110px;
}

.rd_research {
  background-color: var(--bg-ebebec);
}

.rd_research .title2 {
}

.rd_research h4 {
  color: var(--text-58595b);
  font-size: 22px;
  font-weight: normal;
}

.discover {
  background-color: var(--primary);
}

.discover .title2 {
  margin-bottom: 0;
  line-height: 0.8;
}

.discover h4 {
  font-size: 22px;
}

.discover img {
  border-radius: 50%;
  border: 2px solid var(--bg-ffffff);
}

.faq {
}

.faq .row > div:first-child {
  background-color: var(--quaternary);
  padding: 40px 90px 50px 90px;
}

.faq .title {
}

.faq .row > div:last-child {
  background-color: var(--tertiary);
}

.faq .title2 {
  color: var(--text-white);
  text-align: left;
}

.faq p {
  color: var(--text-white);
  margin-bottom: 40px;
  /* font-size: 20px; */
  text-align: justify;
  font-weight: normal;
}

.faq img {
}

.faq .accordion {
  width: 100%;
}

.faq .accordion .accordion-item {
  border-radius: 0px;
  margin-bottom: 15px;
  background-color: transparent;
  max-width: 500px;
  margin: 0 auto;
  border-bottom: 1px solid var(--quaternary);
  color: var(--text-white);
  text-align: left;
}

.faq .accordion .accordion-item .accordion-header {
}

.faq .accordion .accordion-item:last-child {
  border-bottom: 0;
}

.faq .accordion .accordion-item .accordion-header button {
  border-radius: 50px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 20px;
  /* letter-spacing: 1px; */
}

.faq .accordion .accordion-item .accordion-header button img:nth-of-type(1) {
  height: 90px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 80px;
  margin-right: 30px;
}

.faq .accordion .accordion-item .accordion-header button img:nth-of-type(2) {
  width: 40px;
}

.faq .accordion .accordion-item .accordion-header button span {
  margin-right: auto;
  font-weight: 300;
}

.faq .accordion .accordion-item .accordion-header button:focus {
  box-shadow: none;
}

.faq .accordion .accordion-item .accordion-body {
  padding: 0px 0px 30px 0px;
  font-weight: 200;
}

.faq .accordion .accordion-button img {
  margin-right: 15px;
}

.faq .accordion .accordion-button {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--quaternary-text);
  padding: 10px 20px;
}

.faq .accordion .accordion-button:not(.collapsed) {
  color: var(--quaternary-text);
  background-color: transparent;
  box-shadow: unset;
}

.faq .accordion-button::after {
  width: 30px;
  height: 30px;
  background-size: 30px;
  display: none;
}

.faq .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  color: var(--text-6d6e71);
}

.advantages {
  padding-top: 160px;
}

.advantages .advantages-box {
  text-align: center;
  padding: 30px 0px;
  border: 0;
  border-radius: 10px;
  position: relative;
  padding-top: 110px;
  background: linear-gradient(to bottom, #f9e04f 10%, transparent 60%);
}

.advantages .advantages-box > img {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.advantages .advantages-box h1 {
  color: var(--tertiary-text);
  margin: 10px 0 0 0;
  font-family: var(--heading-font);
  font-size: 24px;
}

.advantages .advantages-box > p {
  font-size: 30px !important;
  font-family: var(--heading-font);
  color: var(--tertiary-text);
  font-weight: bold;
  line-height: 0.7 !important;
}

.advantages .advantages-box p {
  /* font-size: 20px; */
  line-height: 1.2;
}

.advantages .advantages-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  /* margin-top: 40px; */
  color: var(--tertiary-text);
  /* font-weight: 600; */
  font-size: 18px;
}

.advantages .advantages-box ul li {
  margin-top: 20px;
  position: relative;
  /* padding-left: 30px; */
  padding-right: 30px;
  line-height: 1.2;
  text-align: justify;
  list-style-type: disc;
  font-size: 15px;
}

.advantages .advantages-box ul li a {
  color: var(--primary);
  font-weight: 600;
}

/* .advantages .advantages-box ul li:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 6px;
  width: 15px;
  height: 15px;
  background-color: var(--quaternary-text);
  border-radius: 50%;
} */

footer {
  background-color: var(--bg-f0f1f1);
}

footer .logo {
  margin-bottom: 30px;
}

footer h4 {
  font-family: var(--heading-font);
  color: var(--secondary-text);
  font-weight: bold;
  font-size: 35px;
  line-height: 0.8;
}

footer form {
  margin: 20px 0;
}

footer form .form-control {
  border: 1px solid var(--bg-939598);
  border-radius: 30px;
  padding: 15px 20px 15px 40px;
  color: var(--text-a3a3a3);
}

footer form .btn {
  padding: 10px 30px;
  font-weight: normal;
  letter-spacing: 1px;
  background-color: var(--quaternary);
  color: var(--text-white);
  font-size: 22px;
}

footer .footer-item {
  margin-top: 20px;
}

footer .footer-item a {
  cursor: pointer;
  color: var(--secondary-text);
}

footer .footer-item .title2 {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: var(--heading-font);
  color: var(--secondary-text);
  font-weight: bold;
  /*letter-spacing: 1px; */
}

footer .recent-blog-item {
  display: flex;
  margin-bottom: 5px;
}

footer .recent-blog-item img {
  margin-right: 15px !important;
  width: 90px;
  object-fit: contain;
  border-radius: 3px;
}

footer .recent-blog-item p {
  color: var(--secondary-text);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 7px;
  margin-left: 15px;
}

footer .recent-blog-item h6 {
  color: var(--secondary-text);
  font-weight: bold !important;
  font-size: 12px;
  margin-left: 15px;
}

footer .social {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
}

footer .social li {
  display: inline-block;
  margin-right: 8px;
}

footer .social li a {
  text-decoration: none;
}

footer .social li a i {
  width: 35px;
  height: 35px;
  background-color: transparent;
  color: var(--secondary-text);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60%;
  font-size: 30px;
}

footer .useful-links {
  padding: 0;
  margin: 0;
}

footer .useful-links li {
  margin-bottom: 7px;
  font-size: 18px;
  list-style: none;
  font-weight: 600;
}

footer .useful-links a {
  color: var(--secondary-text);
  text-decoration: none;
}

.breadcrumbs-wrapper-new {
  padding: 90px 0;
  background: linear-gradient(var(--primary-50), var(--primary-50));
  background-size: cover;
  background-position: center;
}
.breadcrumbs-wrapper {
  padding: 90px 0;
  background: linear-gradient(var(--primary-50), var(--primary-50)),
    url(../images/landing/breadcrumbs.png);
  background-size: cover;
  background-position: center;
}
.breadcrumbs-wrapper .breadcrumb-item {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  color: var(--tertiary-text);
}
.breadcrumbs-wrapper .breadcrumb-item.active {
  font-weight: bold;
}

.blogs.services .services-item span.number:after {
  display: none;
}
.blogs.services .services-item span.number {
  left: 20px;
  top: 0px;
  background-color: var(--black-80);
  border-radius: 0 0 5px 5px;
}
.blogs.services .services-item img {
  height: 230px;
  object-fit: cover;
}
.blogs.services .services-item h4 {
  color: var(--secondary-text);
  font-family: var(--heading-font);
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 0;
}
.blogs.services .services-item p {
  color: var(--text-a3a3a3);
  font-size: 16px;
  font-weight: 500;
}
.blogs.services .btn {
}

.blog-details {
}
.profile-reviews-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-reviews-wrapper .author-details {
  display: flex;
  align-items: center;
  gap: 10px;
}
.profile-reviews-wrapper .author-details img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--bg-6d6e71);
}
.profile-reviews-wrapper .author-details h6 {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--text-58595b);
}
.profile-reviews-wrapper .author-details span {
  color: var(--text-a3a3a3);
  font-weight: normal;
}
.review-stars {
}
.review-stars ul {
  list-style: none;
  display: flex;
  gap: 5px;
  margin: 0;
  padding: 0;
}
.review-stars .fa-star {
  color: var(--bg-d1d3d4);
}
.review-stars .checked-star {
  color: #f5811e;
}

.blog-detail-inner {
  font-size: 20px;
}
.blog-detail-inner img {
  border-radius: 3px;
  /* border: 2px solid var(--bg-d1d3d4); */
}

.latest-posts {
  background-color: var(--bg-f0f1f1);
  padding: var(--box-padding);
  /* border: 2px solid var(--bg-d1d3d4); */
  border-radius: 3px;
}
.latest-posts .title2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.latest-posts .post-item {
  display: flex;
  align-items: center;
  gap: 15px;
}
.latest-posts .post-item img {
  width: 80px;
  min-width: 80px;
  /* height: 80px; */
  border-radius: 3px;
}
.latest-posts .post-item p {
  color: var(--text-6d6e71);
  margin-bottom: 7px;
}
.latest-posts .post-item h5 {
  font-weight: 600;
  color: var(--text-58595b);
  font-family: var(--body-font);
  font-size: 12px;
  line-height: 0.8;
}

.subscribe-box {
  padding: var(--box-padding);
  border: 2px solid var(--bg-d1d3d4);
  border-radius: 20px;
  margin-top: 60px;
}
.subscribe-box .title2 {
  font-size: 24px;
  margin-bottom: 30px;
}
.subscribe-box .form-control {
  border-radius: 30px;
  padding: 10px 30px;
  border: 2px solid var(--bg-d1d3d4);
}
.subscribe-box .btn {
  padding: 10px 30px;
  font-weight: normal;
}

.contact-sction {
}
.contact-box {
  text-align: center;
  padding: 40px;
  background-color: var(--bg-efeff0);
  border: 1px solid var(--bg-d1d3d4);
  border-radius: 15px;
}
.contact-box h3 {
  font-family: var(--heading-font);
  font-size: 45px;
  color: var(--tertiary-text);
  font-weight: 600;
  line-height: 0.8;
  margin: 0;
}
.contact-box h4 {
  color: var(--text-939598);
  line-height: 1.4;
  margin: 0;
}

.inquiry-section {
}
.inquiry-section img {
  position: absolute;
  left: 20px;
  top: 0px;
  bottom: 0;
  margin: auto;
  width: 30px;
}
.inquiry-section .form-control {
  padding: 24px 30px 24px 90px;
  border-radius: 50px;
  font-size: 22px;
}
.inquiry-section textarea + img {
  margin: unset;
  top: 22px;
}
.inquiry-section .form-control::placeholder {
  color: var(--text-939598);
  font-size: 22px;
  font-weight: 500;
  /* text-transform: uppercase; */
}

.map-section {
  margin-bottom: -5px;
}

.about-page {
  color: var(--tertiary-text);
}
.solution-boxes {
}
.solution-box {
  text-align: center;
  padding: 40px;
  background-color: var(--bg-efeff0);
}
.solution-box img {
  margin-bottom: 30px;
}

.adventure-section {
}
.adventure-experts img {
  width: 200px;
  height: 200px;
  border: 2px solid var(--bg-d1d3d4);
  margin: auto;
  display: block;
}
.adventure-experts h4 {
  color: var(--quaternary-text);
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 5px;
}
.adventure-experts h6 {
  color: var(--text-939598);
}
